import { render, staticRenderFns } from "./Deposit.vue?vue&type=template&id=37da1bfc&scoped=true&"
import script from "./Deposit.vue?vue&type=script&lang=js&"
export * from "./Deposit.vue?vue&type=script&lang=js&"
import style0 from "./Deposit.vue?vue&type=style&index=0&id=37da1bfc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37da1bfc",
  null
  
)

export default component.exports