import ajax from '../assets/uilt/ajax';

let transactionInfo = {
  /**根据身份证+手机号获取交易记录 */
  getByPhoneAndIdCard: req => { return ajax.get({ url: "/back/transaction-info/get-by-phone-and-idCard", param: req.param, success: req.success }) },
  /**分页查询业务列表(机构、平台) */
  getPage: req => { return ajax.get({ url: "/back/transaction-info/get-page", param: req.param, success: req.success }) },
  /**分页查询业务列表(导出) */
  getPageExport: "/back/transaction-info/get-page-export",

  /**根据交易单号查询交易记录 */
  getTransactionInfoByCode: req => { return ajax.get({ url: "/back/transaction-info/get-transactionInfo-by-code", param: req.param, success: req.success }) },
  /**分页查询批量汇款、转账导入的数据 */
  pageBatchRemittanceTransferImport: req => { return ajax.get({ url: "/back/transaction-info/page-batch-remittance-transfer-import", param: req.param, success: req.success }) },
  /**分页查询批量汇款、转账导入的数据(只统计总金额(totalAmount)、总手续费(totalServiceCharge)) */
  totalBatchImport: req => { return ajax.get({ url: "/back/transaction-info/total-batch-import", param: req.param, success: req.success }) },

  /**账户汇款(收款人无账户)-机构操作 */
  accountRemittance: req => { return ajax.post({ url: "/back/transaction-info/account-remittance", param: req.param, success: req.success }) },
  /**现金汇款(双方无账户) */
  cashRemittance: req => { return ajax.post({ url: "/back/transaction-info/cash-remittance", param: req.param, success: req.success }) },
  /**账户汇款(收款人无账户)-机构操作【批量汇款】 */
  batchAccountRemittance: req => { return ajax.post({ url: "/back/transaction-info/batch-account-remittance", param: req.param, success: req.success }) },

  /**客户存款 */
  customerDeposit: req => { return ajax.post({ url: "/back/transaction-info/customer-deposit", param: req.param, success: req.success }) },
  /**客户取款(有账户) */
  customerWithdrawals: req => { return ajax.post({ url: "/back/transaction-info/customer-withdrawals", param: req.param, success: req.success }) },

  /**汇款取款(无账户) */
  remittanceWithdrawals: req => { return ajax.post({ url: "/back/transaction-info/remittance-withdrawals", param: req.param, success: req.success }) },
  /**转账(双方有账户)-机构操作 */
  transferAccounts: req => { return ajax.post({ url: "/back/transaction-info/transfer-accounts", param: req.param, success: req.success }) },
  /**转账(双方有账户)-机构操作【批量转账】 */
  batchTransferAccounts: req => { return ajax.post({ url: "/back/transaction-info/batch-transfer-accounts", param: req.param, success: req.success }) },

  /**交易冲正 */
  transactionReversal: req => { return ajax.post({ url: "/back/transaction-info/transaction-reversal", param: req.param, success: req.success }) },

  /**手动发送验证码 */
  sendVerificationCode: req => { return ajax.get({ url: "/back/transaction-info/send-verification-code", param: req.param, success: req.success }) },

  /**分页查询汇款列表 */
  pageRemittanceList: req => { return ajax.get({ url: "/back/transaction-info/page-Remittance-List", param: req.param, success: req.success }) },

  /**汇款-修改取款人信息 */
  updatePayeeInfo: req => { return ajax.post({ url: "/back/transaction-info/update-payee-info", param: req.param, success: req.success }) },

  /**上传   批量汇款、转账导入数据 */
  batchRemittanceTransferImport: "/back/transaction-info/batch-remittance-transfer-import",
}

export default transactionInfo;