import ajax from '../assets/uilt/ajax';

let business = {
  /**新增业务类型 */
  add: (req) => { return ajax.post({ url: "/back/business/add", param: req.param, success: req.success }) },
  /**更新业务类型 */
  updateById: (req) => { return ajax.post({ url: "/back/business/update-by-id", param: req.param, success: req.success }) },
  /**查询业务类型列表 */
  getList: (req) => { return ajax.get({ url: "/back/business/get-list", param: req.param, success: req.success }) },

  /**新增酬金规则 */
  addRemuneration: (req) => { return ajax.post({ url: "/back/business/add-remuneration", param: req.param, success: req.success }) },
  /**更新酬金规则 */
  updateRemuneration: (req) => { return ajax.post({ url: "/back/business/update-remuneration", param: req.param, success: req.success }) },
  /**查询酬金列表 */
  getRemunerationList: (req) => { return ajax.get({ url: "/back/business/get-remuneration-list", param: req.param, success: req.success }) },

  /**新增手续费规则 */
  addServiceCharge: (req) => { return ajax.post({ url: "/back/business/add-service-charge", param: req.param, success: req.success }) },
  /**更新手续费规则 */
  updateServiceCharge: (req) => { return ajax.post({ url: "/back/business/update-service-charge", param: req.param, success: req.success }) },
  /**查询手续费列表 */
  getServiceChargeList: (req) => { return ajax.get({ url: "/back/business/get-serviceCharge-list", param: req.param, success: req.success }) },

  /**根据业务类型编码查询对应的手续费规则 */
  getServiceChargeByBusinessCode: (req) => {
    return ajax.get({ url: "/back/business/get-serviceCharge-by-businessCode", param: req.param, success: req.success })
  },
  /**根据业务类型编码查询对应酬金规则 */
  getRemunerationByBusinessCode: (req) => {
    return ajax.get({ url: "/back/business/get-remuneration-by-businessCode", param: req.param, success: req.success })
  },

  /**手续费计算 */
  serviceChargeCompute: (req) => {
    return ajax.get({ url: "/back/business/serviceCharge-compute", param: req.param, success: req.success })
  },
}

export default business;